import {useOthersPhotos} from "shared/Hooks/others/useOthersPhotos";

export const useEngOthersData = () => {
  const photos = useOthersPhotos();

  return {
    metaInfo: {
      title: "Other producers",
      ogDescription:
        "WUMAX offers a wide range of products from the world's leading producers in the metalworking industry. We invite you to take a look through the catalogs describing the innovative tool solutions that we provide.",
      hashMap: {
        maford: {title: " - M.A. Ford", ogImage: photos.maford},
        yg1: {title: " - YG1", ogImage: photos.yg1},
        vargus: {title: " - Vargus", ogImage: photos.vargus},
        zcc: {title: " - ZCC", ogImage: photos.zccusa},
      },
    },
    hero: {
      title: "OTHER MANUFACTURERS",
      subtitle:
        "WUMAX offers a wide range of products from the world's leading producers in the metalworking industry. We invite you to take a look through the catalogs describing the innovative tool solutions that we provide.",
      bg: photos.hero,
    },
    maford: {
      linkTag: "maford",
      title: "M.A. Ford",
      subtitle:
        "A company boasting a worldwide reputation as a maker of high-performance tools and precision. Ideal metalworking, efficiency and savings – these are the features that M.A.Ford is known for.",
      links: [
        {
          url:
            "/catalogs/maford/MAFord_2020_Master_Catalog_Interactive.pdf",
          text: "Download Catalogue",
        },
      ],
      bigImg: true,
      src: photos.maford,
    },
    yg1: {
      linkTag: "yg1",
      title: "YG1",
      subtitle:
        "One of the world’s leading producers in the cutting tool industry. Many years of experience enable YG1 to introduce modern solutions that ensure high performance while keeping down prices.",
      links: [
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/MILLING-TOOLS.pdf",
          text: "Milling - New Catalogue YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/HOLEMAKING-TOOLS.pdf",
          text: "Holemaking - New Catalogue YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/THREADING-TOOLS.pdf",
          text: "Threading - New Catalogue YE20",
        },
        {
          url:
            "https://www.yg-1.pl/images/katalogi/YE-IT20_INDEXABLE_INSERT-interactive-ver1-5.pdf",
          text: "Indexable – New Catalogue YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/2019/TOOLING-SYSTEM.pdf",
          text: "Tooling system – New Catalogue YE20",
        },
        {
          url: "https://www.yg-1.pl/images/katalogi/Tooling_System.pdf",
          text: "Tool holders",
        },
      ],
      bigImg: true,
      src: photos.yg1,
    },
    zcc: {
      linkTag: "zcc",
      title: "ZCC",
      subtitle:
        "High-quality tools for turning, milling and drilling. They’re characterized by strength and stability, developed with the experience of a global manufacturer.",
      links: [
        {
          url:
            "https://www.zccct-europe.net/web/documents/Produkte/600_CAT_PL.pdf",
          text: "Main Catalogue",
        },
        {
          url:
            "https://www.zccct-europe.net/web/documents/Produkte/634_PNK_3-20_PL.pdf",
          text: "New Products",
        },
      ],
      bigImg: true,
      src: photos.zccusa,
    },
    vargus: {
      linkTag: "vargus",
      title: "VARGUS",
      subtitle:
        "One of the world leaders in production of high quality tools for cutting, gouging, and deburring metals and plastic. Innovation based on experience and reputation – this describes VARGUS tools.",
      links: [
        {
          url: "https://www.vargus.com/groovex-catalogs",
          text: "Groovex Catalogue",
        },
        {
          url: "https://www.vargus.com/vardex-catalogs",
          text: "Vardex Catalogue",
        },
        {
          url: "https://www.vargus.com/shaviv-catalogs",
          text: "Shaviv Catalogue",
        },
      ],
      bigImg: true,
      src: photos.vargus,
    },
  };
};
