import React from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Others = ({data}) => {
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black">
      </Hero>
      <SectionsWrappper>
        <Section data={data.maford} reverse/>
        <Section data={data.yg1}/>
        <Section data={data.vargus} reverse/>
        <Section data={data.zcc}/>
      </SectionsWrappper>
    </>
  );
};

export default Others;
