import React from "react";
import Others from "sections/Others/Others";
import { useEngOthersData } from "lang/en/others";

const OthersPage = () => {
  const data = useEngOthersData();
  return <Others data={data} />;
};

export default OthersPage;
