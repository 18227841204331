import { graphql, useStaticQuery } from "gatsby";

export const useOthersPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "image/others/others_hero.png" }) {
        childImageSharp {
          fluid(maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      maford: file(relativePath: { eq: "image/others/maford-photo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      yg1: file(relativePath: { eq: "image/others/yg1-photo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      zccusa: file(relativePath: { eq: "image/others/zcc-photo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      vargus: file(relativePath: { eq: "image/others/vargus-photo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
